import React from "react";
import { Helmet } from "react-helmet";

const SEOHelmet = ({ title, description }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title> {title} </title>
        <meta property="og:title" title={title} />
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default SEOHelmet;
