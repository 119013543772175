import * as React from "react";
import styled from "styled-components";
import "../../../styles/global.css";

interface IBannerProps {
  title: string;
  subTitle: React.FC;
}

const OverlayContent = (props: IBannerProps) => (
  <>
    <OverlayTitle>{props.title}</OverlayTitle>
    <OverlayDivider />
    <OverlayText>{props.subTitle()}</OverlayText>
  </>
);

const OverlayTitle = styled.h1`
  margin: 0;
  color: white;
  font-size: 68px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 1200px) {
    font-size: 32px;
  }
`;

const OverlayDivider = styled.div`
  height: 2px;
  background: white;
  width: 200px;
  margin: auto;
  margin-top: 20px;
  @media (max-width: 1200px) {
    width: 100px;
    margin-top: 10px;
  }
`;

const OverlayText = styled.div`
  color: white;
  font-size: 23px;
  line-height: 28px;
  font-weight: 400;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 50%;
    line-height: 22px;
    display: none;
  }
`;

export default OverlayContent;
