import * as React from "react";
import styled from "styled-components";
import { pages } from "../../constants";
import Grid from "@material-ui/core/Grid";
import Logo from "../../images/icon.png";
import { Link } from "gatsby";
import { theme } from "../../constants";
import InfoField from "../../components/InfoField";

const AppFooter = () => {
  return (
    <Footer>
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LinkContainer>
              {pages.map((menuItem) => (
                <StyledLink to={menuItem.link} state={{ scrollToTop: true }}>
                  {" "}
                  {menuItem.text}{" "}
                </StyledLink>
              ))}
            </LinkContainer>
          </Grid>
          <Grid xs={12}>
            <FooterDivider />
          </Grid>
          <Grid item xs={12} md={4} justifyContent="center">
            <InfoContainer> Phone Number: 905-391-9999 </InfoContainer>
          </Grid>
          <Grid xs={12} item md={4} alignItems="center">
            <InfoContainer> Email: Memorieslimo@gmail.com </InfoContainer>
          </Grid>
          <Grid xs={12} item md={4}>
            <InfoContainer> Memories Limousine © 2022 </InfoContainer>
          </Grid>
        </Grid>
      </ContentContainer>
    </Footer>
  );
};

const Title = styled.div`
  color: white;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
`;

const Footer = styled.div`
  background: #383838;
  width: 100%;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid lightgrey;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  height: 100%;
  vertical-align: top;
`;
const StyledLink = styled(Link)`
  color: white;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  padding: 5px 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  display: block;
`;

export const ContactButton = styled.div`
  height: 40px;
  width: 150px;
  cursor: pointer;
  font-size: 16px;
  color: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.primaryColor};
  font-weight: 400;
  cursor: pointer;
  margin-left: 50px;
`;
const InfoContainer = styled.div`
  text-align: center;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 960px) {
    display: block;
    text-align: center;
  }
`;

const FooterDivider = styled.div`
  height: 1px;
  width: 85%;
  background: white;
  margin: 5px auto;
`;

export default AppFooter;
