import * as React from "react";
import styled, { keyframes } from "styled-components";
import "../../../styles/global.css";

const BannerOverlay = (props: {children?: React.ReactNode}
) => {

    const {children} = props;

  return (
    <OverlayContainer>
      <TextContainer>
      {children}
      </TextContainer>
    </OverlayContainer>
  );
};

/*--------------Styling-----------------*/

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const TextContainer = styled.div`
  width: 600px;
  top: 50%;
  left: 50%;
  position: absolute;
  color:lightgrey;
  border-radius: 3px;
  margin-top: -10vh;
  margin-left: -300px;
  animation: ${fadeIn} 1s linear;
  transition: visibility 1s linear;
  @media (max-width: 1200px) {
    font-size: 32px;
    margin-top: -5vh;
  }
`


const OverlayContainer = styled.div`
  background:rgba(0,0,0,0.6);
  height: 99%;
  width: 100%;
  position:absolute;
  top: 0;
`
export default BannerOverlay;
