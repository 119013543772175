import * as React from "react";
import styled from "styled-components";
import "../../styles/global.css";
import { Link } from "gatsby";
import BannerImageSource from "../../images/banner.jpeg";
import BannerOverlay from "./BannerOverlay";

interface IBannerProps {
  showOverlay?: Boolean;
  children?: React.ReactNode;
  img: string;
}

const Banner: React.FC<IBannerProps> = (props) => {
  const { showOverlay, children, img } = props;
  return (
    <BannerContainer>
      <BannerImage src={img} alt="banner" />
      {showOverlay && (
        <BannerOverlay
          title={"Welcome to My Sample Website"}
          text={
            "I am some sample text. It is very easy to edit what i say. Dont forget to edit me. Im important text."
          }
          buttonText={"Book Now"}
          link={"/contact"}
        >
          {" "}
          {children}{" "}
        </BannerOverlay>
      )}
    </BannerContainer>
  );
};

/*--------------Styling-----------------*/

const BannerContainer = styled.div`
  position: relative;
`;

const BannerImage = styled.img`
  width: 100%;
  max-height: 90vh;
  min-height: 40vh;
`;

export default Banner;
